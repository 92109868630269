.variantSelectedListModal {
  .closeicon {
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;
    background: #000000;
    border-radius: 50%;
    height: 20px;
    width: 20px;

    img {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      max-width: 20px;
    }
  }
  .title {
      font-size: 18px;
      font-weight: 600;
      padding: 10px 20px 20px 20px;
  }

  .item-list {
      display: flex;
      flex-direction: column;
      padding: 0 20px;
      max-height: 400px;
      overflow: auto;

      .item {
          display: flex;
          padding: 12px 0;
          .left {
              padding-right: 8px;
              flex: 0 1 65%;
              .item-name {
                  font-size: 14px;
                  font-weight: 500;
                  padding-bottom: 6px;
              }
              .variant-name {
                  font-size: 12px;
                  font-weight: 300;
              }
          }
          .right {
              flex: 0 1 35%;
              display: flex;
              justify-content: flex-end;
              align-items: center;
              padding-left: 4px;

              .atc-btn {
                  border: 0;
                  background-color: #fff;
                  font-size: 13px;
                  font-weight: 600;
                  border: 1px solid #ddd;
                  border-radius: 50px;
                  width: fit-content;
                  p {
                      border-radius: 50px;
                      margin: 0;
                      padding: 5px 10px;
                      color: #1e9848;
                      white-space: nowrap;
                  }
                  small {
                      padding: 5px 2px;
                      color: #000;
                      font-size: 13px;
                      font-weight: 500;
                      display: inline-block;
                      vertical-align: middle;
                  }
                  span {
                      font-weight: 600;
                      color: #1e9848;
                      font-size: 13px;
                      vertical-align: middle;
                      img {
                          max-width: 10px;
                          vertical-align: middle;
                      }
                      &:first-child {
                          padding: 5px 7px;
                          display: inline-block;
                      }
                      &:last-child {
                          padding: 5px 7px;
                          display: inline-block;
                      }
                  }
              }
          }
      }
  }
}