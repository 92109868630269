.alertFlow {
  &.backdrop {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    height: 100%;
    width: 100%;
    z-index: 999;
    background: rgba(0,0,0,0.6);
  }

  &__body {
    background: #ffffff;
    padding: 24px 21px 16px 21px;
    position: absolute;
    top: 50%;
    left: 50%;
    max-width: 350px;
    width: 90%;
    transform: translate(-50%, -50%);
    border-radius: 4px;

    h2 {
      font-size: 20px;
      font-weight: 600;
      color: #181818;
      margin: 0 0 15px 0;
    }

    p {
      color: #181818;
      font-size: 16px;
      margin: 0 0 10px 0;
    }

    .button-system {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      padding: 0 10px;
      margin-top: 20px;

      button {
        outline: none;
        background: none;
        box-shadow: none;
        border: none;
        font-size: 16px;
        text-transform: uppercase;
        font-weight: 500;
        cursor: pointer;

        &:first-child {
          margin-right: 20px;
        }
      }
    }
  }
}