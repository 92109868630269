.loader-wrap {
  position: fixed;
  top: 0;
  left: 0;
  background: #ffffffcc;
  width: 100%;
  height: 100%;
  z-index: 9;
  display: flex;
  align-items: center;
  justify-content: center;
  .loadersmall {
    border: 5px solid #47a517;
    animation: spin 1s linear infinite;
    border-top: 5px solid #ffffff;
    border-radius: 50%;
    width: 65px;
    height: 65px;
  }
  img {
    position: fixed;
    max-width: 90px;
  }
  span{
    position: fixed;
  }
}
