@import "./animations.scss";

.flexRowAlign {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.flexNormal {
  display: flex;
  align-items: center;
}
.flexRow {
  display: flex;
}
.flexColumn {
  display: flex;
  flex-direction: column;
}
.flex1 {
  flex: 1;
}
.gap1 {
  gap: 0.25rem;
}
.gap2 {
  gap: 0.5rem;
}
.gap3 {
  gap: 0.625rem;
}
.gap4 {
  gap: 0.75rem;
}
.gap5 {
  gap: 0.875rem;
}
.gap6 {
  gap: 1rem;
}
.gap7 {
  gap: 1.25rem;
}
.gap8 {
  gap: 1.5rem;
}
.gap9 {
  gap: 1.625rem;
}
.gap10 {
  gap: 1.75rem;
}
.gap11 {
  gap: 2rem;
}
.txtCenter {
  text-align: center;
}
.theme-btn {
  background: #58b528;
  color: #fff;
  font-size: 14px;
  padding: 10px 15px;
  display: inline-block;
  border-radius: 4px;
  margin-top: 10px;
}
.theme-bg {
  background: #58b528;
}
.ml10 {
  margin-left: 10px;
}
.mb10 {
  margin-bottom: 10px;
}
.slick-slider.slick-initialized {
  width: 100%;
}
.slick-initialized .slick-slide {
  display: block;
  padding: 10px;
}
.slick-slide img {
  margin-left: auto !important;
  margin-right: auto !important;
}

.modal-close-icon {
  position: absolute;
  top: 10px;
  right: 10px;
  background-color: #000000;
  width: 25px;
  height: 25px;
  border-radius: 30px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  & > img {
    max-width: 100%;
    height: 20px;
  }
}

.btn-reset {
  background-color: transparent;
  border: 0;
  padding: 0;
  margin: 0;
}

.mtAuto {
  margin-top: auto;
}

.pa8 {
  padding: 14px;
}

.pa9 {
  padding: 20px;
}

.obFit {
  object-fit: contain;
}

.w-fit {
  width: fit-content;
}

.rodal-open {
  overflow: hidden !important;
}

.ds-font-manrope {
  font-family: var(--font-manrope);
}
