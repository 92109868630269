.VariantRadioGroup {
  .radio-item {
    display: flex;
    border: 1px solid #D8D8D8;
    border-radius: 18px;
    margin: 10px 0;
    flex: 1 1 100%;
    padding: 15px;

    &:first-child {
      margin-top: 0;
    }

    &:last-child {
      margin-bottom: 0;
    }

    & > input {
      margin-right: 10px;
    }

    & > input[type="radio"]:checked {
      color: #000000;
      background-color: #bbbbbb;
      border-color: #bbbbbb;
    }

    & > span {
      font-size: 14px;
      color: #343638;
      position: relative;
      top: 1px;
    }
  }

  & > .active {
    border: 1px solid #bbbbbb;
    color: #bbbbbb;
    font-weight: 500;
  }
  .out-of-stock {
    border: 1px dashed #bbbbbbcb !important;
    background-color: #eeeeee;
    opacity: 0.6;
  }
}