// -------- MARGIN  --------------- //
// -----0px-------- //

.mt0px {
  margin-top: 0px !important;
}

.mr0px {
  margin-right: 0px !important;
}

.ml0px {
  margin-left: 0px !important;
}

.mb0px {
  margin-bottom: 0px !important;
}

.pb0px {
  padding-bottom: 0px !important;
}

.pl0px {
  padding-left: 0px !important;
}

// ---- 2px ------ //
.ma2px {
  margin: 2px;
}

.mv2px {
  margin-top: 2px;
  margin-bottom: 2px;
}

.mh2px {
  margin-left: 2px;
  margin-right: 2px;
}

.mt2px {
  margin-top: 2px;
}
.mb2px {
  margin-bottom: 2px;
}
.ml2px {
  margin-left: 2px;
}
.mr2px {
  margin-right: 2px;
}

// ---- 4px ------ //
.ma4px {
  margin: 4px;
}

.mv4px {
  margin-top: 4px;
  margin-bottom: 4px;
}

.mh4px {
  margin-left: 4px;
  margin-right: 4px;
}

.mt4px {
  margin-top: 4px;
}
.mb4px {
  margin-bottom: 4px;
}
.ml4px {
  margin-left: 4px;
}
.mr4px {
  margin-right: 4px;
}

// ---- 6px ------ //
.ma6px {
  margin: 6px;
}

.mv6px {
  margin-top: 6px;
  margin-bottom: 6px;
}

.mh6px {
  margin-left: 6px;
  margin-right: 6px;
}

.mt6px {
  margin-top: 6px;
}
.mb6px {
  margin-bottom: 6px;
}
.ml6px {
  margin-left: 6px;
}
.mr6px {
  margin-right: 6px;
}

// ---- 8px ------ //
.ma8px {
  margin: 8px;
}

.mv8px {
  margin-top: 8px;
  margin-bottom: 8px;
}

.mh8px {
  margin-left: 8px;
  margin-right: 8px;
}

.mt8px {
  margin-top: 8px;
}
.mb8px {
  margin-bottom: 8px;
}
.ml8px {
  margin-left: 8px;
}
.mr8px {
  margin-right: 8px;
}

// ---- 10px ------ //
.ma10px {
  margin: 10px;
}

.mv10px {
  margin-top: 10px;
  margin-bottom: 10px;
}

.mh10px {
  margin-left: 10px;
  margin-right: 10px;
}

.mt10px {
  margin-top: 10px;
}
.mb10px {
  margin-bottom: 10px;
}
.ml10px {
  margin-left: 10px;
}
.mr10px {
  margin-right: 10px;
}

// ---- 12px ------ //
.ma12px {
  margin: 12px;
}

.mv12px {
  margin-top: 12px;
  margin-bottom: 12px;
}

.mh12px {
  margin-left: 12px;
  margin-right: 12px;
}

.mt12px {
  margin-top: 12px;
}
.mb12px {
  margin-bottom: 12px;
}
.ml12px {
  margin-left: 12px;
}
.mr12px {
  margin-right: 12px;
}

// ---- 14px ------ //
.ma14px {
  margin: 14px;
}

.mv14px {
  margin-top: 14px;
  margin-bottom: 14px;
}

.mh14px {
  margin-left: 14px;
  margin-right: 14px;
}

.mt14px {
  margin-top: 14px;
}
.mb14px {
  margin-bottom: 14px;
}
.ml14px {
  margin-left: 14px;
}
.mr14px {
  margin-right: 14px;
}

// ---- 16px ------ //
.ma16px {
  margin: 16px;
}

.mv16px {
  margin-top: 16px;
  margin-bottom: 16px;
}

.mh16px {
  margin-left: 16px;
  margin-right: 16px;
}

.mt16px {
  margin-top: 16px;
}
.mb16px {
  margin-bottom: 16px;
}
.ml16px {
  margin-left: 16px;
}
.mr16px {
  margin-right: 16px;
}

// ---- 18px ------ //
.ma18px {
  margin: 18px;
}

.mv18px {
  margin-top: 18px;
  margin-bottom: 18px;
}

.mh18px {
  margin-left: 18px;
  margin-right: 18px;
}

.mt18px {
  margin-top: 18px;
}
.mb18px {
  margin-bottom: 18px;
}
.ml18px {
  margin-left: 18px;
}
.mr18px {
  margin-right: 18px;
}

// ---- 20px ------ //
.ma20px {
  margin: 20px;
}

.mv20px {
  margin-top: 20px;
  margin-bottom: 20px;
}

.mh20px {
  margin-left: 20px;
  margin-right: 20px;
}

.mt20px {
  margin-top: 20px;
}
.mb20px {
  margin-bottom: 20px;
}
.ml20px {
  margin-left: 20px;
}
.mr20px {
  margin-right: 20px;
}

// ---- 22px ------ //
.ma22px {
  margin: 22px;
}

.mv22px {
  margin-top: 22px;
  margin-bottom: 22px;
}

.mh22px {
  margin-left: 22px;
  margin-right: 22px;
}

.mt22px {
  margin-top: 22px;
}
.mb22px {
  margin-bottom: 22px;
}
.ml22px {
  margin-left: 22px;
}
.mr22px {
  margin-right: 22px;
}

// ---- 24px ------ //
.ma24px {
  margin: 24px;
}

.mv24px {
  margin-top: 24px;
  margin-bottom: 24px;
}

.mh24px {
  margin-left: 24px;
  margin-right: 24px;
}

.mt24px {
  margin-top: 24px;
}
.mb24px {
  margin-bottom: 24px;
}
.ml24px {
  margin-left: 24px;
}
.mr24px {
  margin-right: 24px;
}

// ---- 26px ------ //
.ma26px {
  margin: 26px;
}

.mv26px {
  margin-top: 26px;
  margin-bottom: 26px;
}

.mh26px {
  margin-left: 26px;
  margin-right: 26px;
}

.mt26px {
  margin-top: 26px;
}
.mb26px {
  margin-bottom: 26px;
}
.ml26px {
  margin-left: 26px;
}
.mr26px {
  margin-right: 26px;
}

// ---- 28px ------ //
.ma28px {
  margin: 28px;
}

.mv28px {
  margin-top: 28px;
  margin-bottom: 28px;
}

.mh28px {
  margin-left: 28px;
  margin-right: 28px;
}

.mt28px {
  margin-top: 28px;
}
.mb28px {
  margin-bottom: 28px;
}
.ml28px {
  margin-left: 28px;
}
.mr28px {
  margin-right: 28px;
}

// ---- 30px ------ //
.ma30px {
  margin: 30px;
}

.mv30px {
  margin-top: 30px;
  margin-bottom: 30px;
}

.mh30px {
  margin-left: 30px;
  margin-right: 30px;
}

.mt30px {
  margin-top: 30px;
}
.mb30px {
  margin-bottom: 30px;
}
.ml30px {
  margin-left: 30px;
}
.mr30px {
  margin-right: 30px;
}

// ---- 32px ------ //
.ma32px {
  margin: 32px;
}

.mv32px {
  margin-top: 32px;
  margin-bottom: 32px;
}

.mh32px {
  margin-left: 32px;
  margin-right: 32px;
}

.mt32px {
  margin-top: 32px;
}
.mb32px {
  margin-bottom: 32px;
}
.ml32px {
  margin-left: 32px;
}
.mr32px {
  margin-right: 32px;
}

// ---- 34px ------ //
.ma34px {
  margin: 34px;
}

.mv34px {
  margin-top: 34px;
  margin-bottom: 34px;
}

.mh34px {
  margin-left: 34px;
  margin-right: 34px;
}

.mt34px {
  margin-top: 34px;
}
.mb34px {
  margin-bottom: 34px;
}
.ml34px {
  margin-left: 34px;
}
.mr34px {
  margin-right: 34px;
}

// ---- 36px ------ //
.ma36px {
  margin: 36px;
}

.mv36px {
  margin-top: 36px;
  margin-bottom: 36px;
}

.mh36px {
  margin-left: 36px;
  margin-right: 36px;
}

.mt36px {
  margin-top: 36px;
}
.mb36px {
  margin-bottom: 36px;
}
.ml36px {
  margin-left: 36px;
}
.mr36px {
  margin-right: 36px;
}

// ---- 38px ------ //
.ma38px {
  margin: 38px;
}

.mv38px {
  margin-top: 38px;
  margin-bottom: 38px;
}

.mh38px {
  margin-left: 38px;
  margin-right: 38px;
}

.mt38px {
  margin-top: 38px;
}
.mb38px {
  margin-bottom: 38px;
}
.ml38px {
  margin-left: 38px;
}
.mr38px {
  margin-right: 38px;
}

// ---- 40px ------ //
.ma40px {
  margin: 40px;
}

.mv40px {
  margin-top: 40px;
  margin-bottom: 40px;
}

.mh40px {
  margin-left: 40px;
  margin-right: 40px;
}

.mt40px {
  margin-top: 40px;
}
.mb40px {
  margin-bottom: 40px;
}
.ml40px {
  margin-left: 40px;
}
.mr40px {
  margin-right: 40px;
}

// -------- PADDING  --------------- //

// ---- 2px ------ //
.pa2px {
  padding: 2px;
}

.pv2px {
  padding-top: 2px;
  padding-bottom: 2px;
}

.ph2px {
  padding-left: 2px;
  padding-right: 2px;
}

.pt2px {
  padding-top: 2px;
}
.pb2px {
  padding-bottom: 2px;
}
.pl2px {
  padding-left: 2px;
}
.pr2px {
  padding-right: 2px;
}

// ---- 4px ------ //
.pa4px {
  padding: 4px;
}

.pv4px {
  padding-top: 4px;
  padding-bottom: 4px;
}

.ph4px {
  padding-left: 4px;
  padding-right: 4px;
}

.pt4px {
  padding-top: 4px;
}
.pb4px {
  padding-bottom: 4px;
}
.pl4px {
  padding-left: 4px;
}
.pr4px {
  padding-right: 4px;
}

// ---- 6px ------ //
.pa6px {
  padding: 6px;
}

.pv5px {
  padding-top: 5px;
  padding-bottom: 5px;
}

.pv6px {
  padding-top: 6px;
  padding-bottom: 6px;
}

.ph6px {
  padding-left: 6px;
  padding-right: 6px;
}

.pt6px {
  padding-top: 6px;
}
.pb6px {
  padding-bottom: 6px;
}
.pl6px {
  padding-left: 6px;
}
.pr6px {
  padding-right: 6px;
}

// ---- 8px ------ //
.pa8px {
  padding: 8px;
}

.pv8px {
  padding-top: 8px;
  padding-bottom: 8px;
}

.ph8px {
  padding-left: 8px;
  padding-right: 8px;
}

.pt8px {
  padding-top: 8px;
}
.pb8px {
  padding-bottom: 8px;
}
.pl8px {
  padding-left: 8px;
}
.pr8px {
  padding-right: 8px;
}

// ---- 10px ------ //
.pa10px {
  padding: 10px;
}

.pv10px {
  padding-top: 10px;
  padding-bottom: 10px;
}

.ph10px {
  padding-left: 10px;
  padding-right: 10px;
}

.pt10px {
  padding-top: 10px;
}
.pb10px {
  padding-bottom: 10px;
}
.pl10px {
  padding-left: 10px;
}
.pr10px {
  padding-right: 10px;
}

// ---- 12px ------ //
.pa12px {
  padding: 12px;
}

.pv12px {
  padding-top: 12px;
  padding-bottom: 12px;
}

.ph12px {
  padding-left: 12px;
  padding-right: 12px;
}

.pt12px {
  padding-top: 12px;
}
.pb12px {
  padding-bottom: 12px;
}
.pl12px {
  padding-left: 12px;
}
.pr12px {
  padding-right: 12px;
}

// ---- 14px ------ //
.pa14px {
  padding: 14px;
}
.pv13px {
  padding-top: 13px;
  padding-bottom: 13px;
}
.pv14px {
  padding-top: 14px;
  padding-bottom: 14px;
}

.ph14px {
  padding-left: 14px;
  padding-right: 14px;
}

.pt14px {
  padding-top: 14px;
}
.pb14px {
  padding-bottom: 14px;
}
.pl14px {
  padding-left: 14px;
}
.pr14px {
  padding-right: 14px;
}

// ---- 16px ------ //
.pa16px {
  padding: 16px;
}

.pv16px {
  padding-top: 16px;
  padding-bottom: 16px;
}

.ph16px {
  padding-left: 16px;
  padding-right: 16px;
}

.pt16px {
  padding-top: 16px;
}
.pb16px {
  padding-bottom: 16px;
}
.pl16px {
  padding-left: 16px;
}
.pr16px {
  padding-right: 16px;
}

// ---- 18px ------ //
.pa18px {
  padding: 18px;
}

.pv18px {
  padding-top: 18px;
  padding-bottom: 18px;
}

.ph18px {
  padding-left: 18px;
  padding-right: 18px;
}

.pt18px {
  padding-top: 18px;
}
.pb18px {
  padding-bottom: 18px;
}
.pl18px {
  padding-left: 18px;
}
.pr18px {
  padding-right: 18px;
}

// ---- 20px ------ //
.pa20px {
  padding: 20px;
}

.pv20px {
  padding-top: 20px;
  padding-bottom: 20px;
}

.ph20px {
  padding-left: 20px;
  padding-right: 20px;
}

.pt20px {
  padding-top: 20px;
}
.pb20px {
  padding-bottom: 20px;
}
.pl20px {
  padding-left: 20px;
}
.pr20px {
  padding-right: 20px;
}

// ---- 22px ------ //
.pa22px {
  padding: 22px;
}

.pv22px {
  padding-top: 22px;
  padding-bottom: 22px;
}

.ph22px {
  padding-left: 22px;
  padding-right: 22px;
}

.pt22px {
  padding-top: 22px;
}
.pb22px {
  padding-bottom: 22px;
}
.pl22px {
  padding-left: 22px;
}
.pr22px {
  padding-right: 22px;
}

.ph22rem {
  padding-left: 1.375rem;
  padding-right: 1.375rem;
}

// ---- 24px ------ //
.pa24px {
  padding: 24px;
}

.pv24px {
  padding-top: 24px;
  padding-bottom: 24px;
}

.ph24px {
  padding-left: 24px;
  padding-right: 24px;
}

.pt24px {
  padding-top: 24px;
}
.pb24px {
  padding-bottom: 24px;
}
.pl24px {
  padding-left: 24px;
}
.pr24px {
  padding-right: 24px;
}

// ---- 26px ------ //
.pa26px {
  padding: 26px;
}

.pv26px {
  padding-top: 26px;
  padding-bottom: 26px;
}

.ph26px {
  padding-left: 26px;
  padding-right: 26px;
}

.pt26px {
  padding-top: 26px;
}
.pb26px {
  padding-bottom: 26px;
}
.pl26px {
  padding-left: 26px;
}
.pr26px {
  padding-right: 26px;
}

// ---- 28px ------ //
.pa28px {
  padding: 28px;
}

.pv28px {
  padding-top: 28px;
  padding-bottom: 28px;
}

.ph28px {
  padding-left: 28px;
  padding-right: 28px;
}

.pt28px {
  padding-top: 28px;
}
.pb28px {
  padding-bottom: 28px;
}
.pl28px {
  padding-left: 28px;
}
.pr28px {
  padding-right: 28px;
}

// ---- 30px ------ //
.pa30px {
  padding: 30px;
}

.pv30px {
  padding-top: 30px;
  padding-bottom: 30px;
}

.ph30px {
  padding-left: 30px;
  padding-right: 30px;
}

.pt30px {
  padding-top: 30px;
}
.pb30px {
  padding-bottom: 30px;
}
.pl30px {
  padding-left: 30px;
}
.pr30px {
  padding-right: 30px;
}

// ---- 32px ------ //
.pa32px {
  padding: 32px;
}

.pv32px {
  padding-top: 32px;
  padding-bottom: 32px;
}

.ph32px {
  padding-left: 32px;
  padding-right: 32px;
}

.pt32px {
  padding-top: 32px;
}
.pb32px {
  padding-bottom: 32px;
}
.pl32px {
  padding-left: 32px;
}
.pr32px {
  padding-right: 32px;
}

// ---- 34px ------ //
.pa34px {
  padding: 34px;
}

.pv34px {
  padding-top: 34px;
  padding-bottom: 34px;
}

.ph34px {
  padding-left: 34px;
  padding-right: 34px;
}

.pt34px {
  padding-top: 34px;
}
.pb34px {
  padding-bottom: 34px;
}
.pl34px {
  padding-left: 34px;
}
.pr34px {
  padding-right: 34px;
}

// ---- 36px ------ //
.pa36px {
  padding: 36px;
}

.pv36px {
  padding-top: 36px;
  padding-bottom: 36px;
}

.ph36px {
  padding-left: 36px;
  padding-right: 36px;
}

.pt36px {
  padding-top: 36px;
}
.pb36px {
  padding-bottom: 36px;
}
.pl36px {
  padding-left: 36px;
}
.pr36px {
  padding-right: 36px;
}

// ---- 38px ------ //
.pa38px {
  padding: 38px;
}

.pv38px {
  padding-top: 38px;
  padding-bottom: 38px;
}

.ph38px {
  padding-left: 38px;
  padding-right: 38px;
}

.pt38px {
  padding-top: 38px;
}
.pb38px {
  padding-bottom: 38px;
}
.pl38px {
  padding-left: 38px;
}
.pr38px {
  padding-right: 38px;
}

// ---- 40px ------ //
.pa40px {
  padding: 40px;
}

.pv40px {
  padding-top: 40px;
  padding-bottom: 40px;
}

.ph40px {
  padding-left: 40px;
  padding-right: 40px;
}

.pt40px {
  padding-top: 40px;
}

.pt44px {
  padding-top: 40px;
}

.pt50px {
  padding-top: 50px;
}

.pb40px {
  padding-bottom: 40px;
}

.pl40px {
  padding-left: 40px;
}

.pr40px {
  padding-right: 40px;
}
