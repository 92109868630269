.variantModal {
  position: relative;

  .variantModal-section {
    display: flex;
    padding: 0px 20px;
    position: relative;

    .variantModal-closeicon {
      position: absolute;
      top: 10px;
      right: 10px;
      cursor: pointer;
      background: #000000;
      border-radius: 50%;
      height: 20px;
      width: 20px;

      img {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        max-width: 20px;
      }
    }

    h3 {
      color: #2a2a2a;
      font-size: 16px;
      padding-top: 16px;
    }
  }

  .variant-list {
    padding: 0px 20px 100px 20px;
    max-height: 400px;
    overflow: auto;
  }

  .add-to-cart {
    position: absolute;
    bottom: 0;
    width: 100%;
    z-index: 999;
    background-color: #ffffff;
    padding: 10px 20px;
    box-shadow: 0px -3px 6px #00000014;

    & > button {
      width: 100%;
      border-radius: 24px;
      background-color: #000;
      color: #ffffff;
      box-shadow: 0 3px 6px #00000029;
      text-align: center;
      outline: none;
      border: 1px solid #000;
      padding: 12px;
      font-size: 17px;
    }
  }
}
