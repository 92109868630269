.variantChooseRepeatModal {
  .closeicon {
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;
    background: #000000;
    border-radius: 50%;
    height: 20px;
    width: 20px;

    img {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      max-width: 20px;
    }
  }

  .title {
    font-size: 18px;
    font-weight: 600;
    padding: 10px 20px 20px 20px;
  }

  .sub-title {
    padding: 0 20px 7px 20px;
    font-size: 16px;
    font-weight: 500;
  }

  .variant-name {
    padding: 0 20px;
    font-size: 13px;
    font-weight: 300;
  }

  .footer {
    padding: 20px 20px 0 20px;
    display: flex;
    // flex: auto;
    & > button {
      padding: 14px 0;
      border-radius: 22px;
      border: 1px solid #000;
      font-size: 15px;
    }

    & > :first-child {
      margin-right: 20px;
    }
    .choose_btn {
      width: 50%;
      background-color: #ffffff;
      color: #000;
    }
    .repeat_btn {
      width: 50%;
      background-color: #000;
      color: #ffffff;
    }
  }
}
