.f2px {
  font-size: 2px;
}

.f4px {
  font-size: 4px;
}

.f6px {
  font-size: 6px;
}

.f8px {
  font-size: 8px;
}

.f10px {
  font-size: 10px;
}
.f12px {
  font-size: 12px;
}

.f13px {
  font-size: 13px;
}

.f14px {
  font-size: 14px;
}

.f16px {
  font-size: 16px;
}

.f18px {
  font-size: 18px;
}

.f20px {
  font-size: 20px;
}

.f21px {
  font-size: 21px;
}

.f22px {
  font-size: 22px;
}

.f24px {
  font-size: 24px;
}

.f26px {
  font-size: 26px;
}

.f28px {
  font-size: 28px;
}

.f30px {
  font-size: 30px;
}

.f32px {
  font-size: 32px;
}

.f24rem {
  font-size: 1.5rem;
}

.f22rem {
  font-size: 1.375rem;
}

.f34px {
  font-size: 34px;
}

.f36px {
  font-size: 36px;
}

.f38px {
  font-size: 38px;
}

.f40px {
  font-size: 40px;
}

.f42px {
  font-size: 42px;
}

/* f[px]rem*/

.f2rem {
  font-size: 0.125rem;
}

.f4rem {
  font-size: 0.25rem;
}

.f6rem {
  font-size: 0.375rem;
}

.f8rem {
  font-size: 0.5rem;
}

.f10rem {
  font-size: 0.625rem;
}

.f12rem {
  font-size: 0.75rem;
}

.f13rem {
  font-size: 0.8125rem;
}

.f14rem {
  font-size: 0.875rem;
}

.f16rem {
  font-size: 1rem;
}

.f18rem {
  font-size: 1.125rem;
}

.f20rem {
  font-size: 1.25rem;
}

.f21rem {
  font-size: 1.3125rem;
}

.f22rem {
  font-size: 1.375rem;
}

.f24rem {
  font-size: 1.5rem;
}

.f26rem {
  font-size: 1.625rem;
}

.f28rem {
  font-size: 1.75rem;
}

.f30rem {
  font-size: 1.875rem;
}

.f32rem {
  font-size: 2rem;
}

.f34rem {
  font-size: 2.125rem;
}

.f36rem {
  font-size: 2.25rem;
}

.f38rem {
  font-size: 2.375rem;
}

.f40rem {
  font-size: 2.5rem;
}

.f42rem {
  font-size: 2.625rem;
}
