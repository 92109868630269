/*
   Importing Font
*/
@import url("https://fonts.googleapis.com/css2?family=Plus+Jakarta+Sans:wght@300;400;500;600;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Manrope:wght@300;400;500;600;700&display=swap");

@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  box-sizing: border-box;
}
:root {
  --primary-color: #59b528;
  --dark-color: #211b3e;
  --pale-grey-two: #eeecf9;
  --pale-grey: #f8f7fa;
  --blue-grey: #8d8b97;
  --dusk: #585371;
  --dark: #211b3e;
  --cloudy-blue: #c0becd;
  --pale-grey-two: #eeecf9;
  --white: #fff;
  --regular: regular;
  --heavy: heavy;
  --fixed-header-height: 50px;
  --font-jakarta: "Plus Jakarta Sans";
  --font-manrope: "Manrope";
}

body {
  font-family: "Source Sans Pro", sans-serif;
  font-weight: 400;
  background: var(--pale-grey);
}

button {
  outline: none !important;
}

.page-loader {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
